import {api_version} from './api-version';

let baseURL: string = '';
let apiReportingUrlPrefix: string = 'reporting.o2m'

declare global {
    interface Window {
        TV: any;
    }
}

if (window.TV && window.TV.baseURL !== '${BASEURL}') {
    baseURL = window.TV.baseURL;
}

export const environment: any = {
    production: true,
    keycloakUrl: `login.${baseURL}/auth`,
    sentryDsn: 'https://5db98ed04218413988170083c2568257@infra-sentry.v10s.net/10',
    apiUrl: `api.${baseURL}/${api_version}`,
    apiReportingUrl: `${apiReportingUrlPrefix}.${baseURL}`,
    protocol: window.location.protocol.slice(0, -1),
    // eslint-disable-next-line max-len
    agGridEnterpriseKey: btoa('Using_this_{AG_Grid}_Enterprise_key_{AG-055041}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Virtual_Minds_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Adition.TV}_only_for_{6}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Adition.TV}_need_to_be_licensed___{Adition.TV}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{25_April_2025}____[v3]_[01]_MTc0NTUzNTYwMDAwMA==7a3a8961a1403207d15450dee2fa246e'),
};
